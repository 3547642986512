var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$fetchState.pending || !_vm.blockData)?_c('div'):_c('VueSlickCarousel',{staticClass:"logo-carousel",attrs:{"arrows":false,"draggable":false,"autoplay":true,"infinite":true,"autoplay-speed":3000,"speed":3000,"slides-to-show":6,"slides-to-scroll":1,"css-ease":"linear","pause-on-hover":false,"responsive":[
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    } ]}},_vm._l((_vm.blockData),function(value,key,index){return _c('div',{key:key,staticClass:"carousel__item"},[_c('SfImage',{attrs:{"src":value.logo,"alt":value.key || 'carousel-logo',"loading":"lazy","image-tag":"nuxt-img","placeholder":_vm.addBasePath(
          _vm.logoList[index < 4 ? index : Math.floor(Math.random() * 4)].path
        )}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }