var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfModal',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal'),expression:"'login-modal'"}],staticClass:"login-modal",attrs:{"visible":_vm.isLoginModalOpen},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-bar",fn:function(){return [_c('SfBar',{staticClass:"sf-modal__bar smartphone-only",attrs:{"close":true,"title":_vm.$t(_vm.barTitle)},on:{"click:close":_vm.closeModal}})]},proxy:true}])},[_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.isLogin)?_c('div',[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogin)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","placeholder":_vm.$t('Insert your email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","placeholder":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"action"},[_c('SfButton',{staticClass:"sf-button--text",on:{"click":function($event){return _vm.setIsForgottenValue(true)}}},[_vm._v("\n                "+_vm._s(_vm.$t('Forgotten password?'))+"\n              ")])],1),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('Recaptcha'):_vm._e(),_vm._v(" "),(_vm.error.login)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t(_vm.error.login))+"\n            ")]):_vm._e(),_vm._v(" "),_c('CustomButton',{attrs:{"disabled":_vm.loading}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('span',[_vm._v(_vm._s(_vm.$t('Login')))])])],1)],1)]}}],null,false,2311563442)}),_vm._v(" "),_c('div',{staticClass:"bottom"},[_vm._v("\n          "+_vm._s(_vm.$t('Not yet registered?'))+"\n          "),_c('SfButton',{staticClass:"sf-button--text",on:{"click":function($event){return _vm.setIsLoginValue(false)}}},[_c('b',[_vm._v(_vm._s(_vm.$t('Create a new account')))])])],1)],1):(_vm.isForgotten)?_c('div',[_c('p',{staticClass:"forgot-password-message"},[_vm._v(_vm._s(_vm.$t('Forgot Password')))]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleForgotten)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-modal-email'),expression:"'forgot-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","placeholder":_vm.$t('Forgot Password Modal Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('Recaptcha'):_vm._e(),_vm._v(" "),(_vm.forgotPasswordError.request)?_c('div',{staticClass:"thank-you"},[_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'It was not possible to request a new password, please check the entered email address.'
                  ))+"\n              ")])]):_vm._e(),_vm._v(" "),_c('CustomButton',{attrs:{"disabled":_vm.forgotPasswordLoading}},[_c('SfLoader',{class:{ loader: _vm.forgotPasswordLoading },attrs:{"loading":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Reset Password')))])])],1)],1)]}}])})],1):(_vm.isThankYouAfterForgotten)?_c('div',{staticClass:"thank-you"},[_c('i18n',{staticClass:"thank-you__paragraph",attrs:{"tag":"p","path":"forgotPasswordConfirmation"}},[_c('span',{staticClass:"thank-you__paragraph--bold"},[_vm._v(_vm._s(_vm.userEmail))])]),_vm._v(" "),_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t('Thank You Inbox'))+"\n        ")])],1):_c('div',{staticClass:"form"},[_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var handleSubmit = ref.handleSubmit;
                  var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleRegister)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","placeholder":_vm.$t('Your email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name","placeholder":_vm.$t('First Name')},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last-name","placeholder":_vm.$t('Last Name')},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                regex: /^[A-Za-z]{6}(?:\d{2}[A-Za-z]){2}\d{3}[A-Za-z]$/,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",attrs:{"name":"fiscalCode","placeholder":_vm.$t('Fiscal Code'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.tax_code),callback:function ($$v) {_vm.$set(_vm.form, "tax_code", $$v)},expression:"form.tax_code"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",class:{
                  'password-error': errors[0] && errors[0].length > 30,
                },attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","placeholder":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element repassword",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"re-password","placeholder":_vm.$t('Re-Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.repassword),callback:function ($$v) {_vm.$set(_vm.form, "repassword", $$v)},expression:"form.repassword"}})]}}],null,true)}),_vm._v(" "),_c('ConditionsAcceptance',{directives:[{name:"e2e",rawName:"v-e2e",value:('privacy'),expression:"'privacy'"}],staticClass:"form__element",on:{"valueChange":_vm.changePrivacy}}),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element",attrs:{"label":_vm.$t('Sign Up for Newsletter'),"name":"signupNewsletter"},model:{value:(_vm.isSubscribed),callback:function ($$v) {_vm.isSubscribed=$$v},expression:"isSubscribed"}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-create-account'),expression:"'login-modal-create-account'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"create-account","label":_vm.$t('I want to create an account')},model:{value:(_vm.createAccount),callback:function ($$v) {_vm.createAccount=$$v},expression:"createAccount"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('Recaptcha'):_vm._e(),_vm._v(" "),(_vm.error.register)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t(_vm.error.register))+"\n            ")]):_vm._e(),_vm._v(" "),_c('CustomButton',{attrs:{"disabled":_vm.loading || !_vm.createAccount || invalid || !_vm.privacyChecked}},[_c('SfLoader',{class:{ loader: _vm.loading || _vm.brevoLoading },attrs:{"loading":_vm.loading || _vm.brevoLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create an account')))])])],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"action"},[_vm._v("\n          "+_vm._s(_vm.$t('or'))+"\n          "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-login-to-your-account'),expression:"'login-modal-login-to-your-account'"}],staticClass:"sf-button--text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_c('b',[_vm._v(_vm._s(_vm.$t('login in to your account')))])])],1)],1)])],1),_vm._v(" "),(_vm.hasLoggedIn)?_c('CustomerGtm',{attrs:{"is-logged":_vm.isLoggedIn,"customer-data":{ user: _vm.user }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }