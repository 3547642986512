<template>
  <div>
    <SfModal
      v-e2e="'login-modal'"
      :visible="isLoginModalOpen"
      class="login-modal"
      @close="closeModal"
    >
      <template #modal-bar>
        <SfBar
          class="sf-modal__bar smartphone-only"
          :close="true"
          :title="$t(barTitle)"
          @click:close="closeModal"
        />
      </template>
      <transition name="sf-fade" mode="out-in">
        <div v-if="isLogin">
          <ValidationObserver v-slot="{ handleSubmit }" key="log-in">
            <form class="form" @submit.prevent="handleSubmit(handleLogin)">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <CustomInput
                  v-model="form.username"
                  v-e2e="'login-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :placeholder="$t('Insert your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.password"
                  v-e2e="'login-modal-password'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :placeholder="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <div class="action">
                <SfButton
                  class="sf-button--text"
                  @click="setIsForgottenValue(true)"
                >
                  {{ $t('Forgotten password?') }}
                </SfButton>
              </div>
              <Recaptcha v-if="isRecaptchaEnabled" />
              <div v-if="error.login">
                {{ $t(error.login) }}
              </div>
              <CustomButton :disabled="loading">
                <SfLoader :class="{ loader: loading }" :loading="loading">
                  <span>{{ $t('Login') }}</span>
                </SfLoader>
              </CustomButton>
            </form>
          </ValidationObserver>
          <div class="bottom">
            {{ $t('Not yet registered?') }}
            <SfButton class="sf-button--text" @click="setIsLoginValue(false)">
              <b>{{ $t('Create a new account') }}</b>
            </SfButton>
          </div>
        </div>
        <div v-else-if="isForgotten">
          <p class="forgot-password-message">{{ $t('Forgot Password') }}</p>
          <ValidationObserver v-slot="{ handleSubmit }" key="log-in">
            <form class="form" @submit.prevent="handleSubmit(handleForgotten)">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <CustomInput
                  v-model="form.username"
                  v-e2e="'forgot-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :placeholder="$t('Forgot Password Modal Email')"
                  class="form__element"
                />
              </ValidationProvider>
              <Recaptcha v-if="isRecaptchaEnabled" />
              <div v-if="forgotPasswordError.request" class="thank-you">
                <p class="thank-you__paragraph">
                  {{
                    $t(
                      'It was not possible to request a new password, please check the entered email address.'
                    )
                  }}
                </p>
              </div>
              <CustomButton :disabled="forgotPasswordLoading">
                <SfLoader
                  :class="{ loader: forgotPasswordLoading }"
                  :loading="forgotPasswordLoading"
                >
                  <div>{{ $t('Reset Password') }}</div>
                </SfLoader>
              </CustomButton>
            </form>
          </ValidationObserver>
        </div>
        <div v-else-if="isThankYouAfterForgotten" class="thank-you">
          <i18n
            tag="p"
            class="thank-you__paragraph"
            path="forgotPasswordConfirmation"
          >
            <span class="thank-you__paragraph--bold">{{ userEmail }}</span>
          </i18n>
          <p class="thank-you__paragraph">
            {{ $t('Thank You Inbox') }}
          </p>
        </div>
        <div v-else class="form">
          <ValidationObserver v-slot="{ handleSubmit, invalid }" key="sign-up">
            <form
              class="form"
              autocomplete="off"
              @submit.prevent="handleSubmit(handleRegister)"
            >
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <CustomInput
                  v-model="form.email"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :placeholder="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.firstName"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="first-name"
                  :placeholder="$t('First Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.lastName"
                  v-e2e="'login-modal-lastName'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="last-name"
                  :placeholder="$t('Last Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  regex: /^[A-Za-z]{6}(?:\d{2}[A-Za-z]){2}\d{3}[A-Za-z]$/,
                }"
              >
                <CustomInput
                  v-model="form.tax_code"
                  name="fiscalCode"
                  :placeholder="$t('Fiscal Code')"
                  required
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|password"
                vid="password"
              >
                <CustomInput
                  v-model="form.password"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :placeholder="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                  :class="{
                    'password-error': errors[0] && errors[0].length > 30,
                  }"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|confirmed:password"
              >
                <CustomInput
                  v-model="form.repassword"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="re-password"
                  :placeholder="$t('Re-Password')"
                  type="password"
                  has-show-password
                  class="form__element repassword"
                />
              </ValidationProvider>
              <ConditionsAcceptance
                v-e2e="'privacy'"
                class="form__element"
                @valueChange="changePrivacy"
              />
              <SfCheckbox
                v-model="isSubscribed"
                v-e2e="'sign-up-newsletter'"
                :label="$t('Sign Up for Newsletter')"
                name="signupNewsletter"
                class="form__element"
              />
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: { allowFalse: false } }"
              >
                <SfCheckbox
                  v-model="createAccount"
                  v-e2e="'login-modal-create-account'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="create-account"
                  :label="$t('I want to create an account')"
                  class="form__element"
                />
              </ValidationProvider>
              <Recaptcha v-if="isRecaptchaEnabled" />
              <div v-if="error.register">
                {{ $t(error.register) }}
              </div>
              <CustomButton
                :disabled="
                  loading || !createAccount || invalid || !privacyChecked
                "
              >
                <SfLoader
                  :class="{ loader: loading || brevoLoading }"
                  :loading="loading || brevoLoading"
                >
                  <div>{{ $t('Create an account') }}</div>
                </SfLoader>
              </CustomButton>
            </form>
          </ValidationObserver>
          <div class="action">
            {{ $t('or') }}
            <SfButton
              v-e2e="'login-modal-login-to-your-account'"
              class="sf-button--text"
              @click="setIsLoginValue(true)"
            >
              <b>{{ $t('login in to your account') }}</b>
            </SfButton>
          </div>
        </div>
      </transition>
    </SfModal>
    <CustomerGtm
      v-if="hasLoggedIn"
      :is-logged="isLoggedIn"
      :customer-data="{ user }"
    />
  </div>
</template>

<script>
import {
  ref,
  watch,
  reactive,
  defineComponent,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfButton,
  SfCheckbox,
  SfLoader,
  SfBar,
} from '@storefront-ui/vue';
import CustomInput from '~/components/CustomInput.vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed, regex } from 'vee-validate/dist/rules';
import {
  useUser,
  useForgotPassword,
  useWishlist,
} from '@gemini-vsf/composables';
import { useBrevo, useUiNotification, useUiState } from '~/composables';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import CustomerGtm from '~/components/Gtm/CustomerGtm.vue';
import { removeItem } from '~/helpers/asyncLocalStorage';
import CustomButton from '@/components/CustomButton.vue';
import ConditionsAcceptance from '@/components/ConditionsAcceptance.vue';
import Recaptcha from '@nuxtjs/recaptcha/lib/recaptcha.vue';
import { useRecaptcha } from '~/composables/useRecaptcha';
import { useI18n } from '~/helpers/hooks/usei18n';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

extend('regex', {
  ...regex,
  message: 'Invalid fiscal code',
});

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    CustomInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    CustomerGtm,
    CustomButton,
    ConditionsAcceptance,
    Recaptcha,
  },
  setup() {
    const { isLoginModalOpen, toggleLoginModal } = useUiState();
    const isSubscribed = ref(false);
    const privacyChecked = ref(false);
    const form = ref({});
    const isLogin = ref(true);
    const createAccount = ref(false);
    const rememberMe = ref(false);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const { isEnabled: isRecaptchaEnabled, $recaptcha } = useRecaptcha();
    const { app } = useContext();
    const router = useRouter();
    const {
      createContact,
      loading: brevoLoading,
      error: brevoError,
    } = useBrevo();
    const translate = useI18n();
    const { send: sendNotification } = useUiNotification();

    const { register, login, loading, user, error: userError } = useUser();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { load: loadWishlist } = useWishlist('GlobalWishlist');
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const {
      request,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();
    const isLoggedIn = ref(false);

    const barTitle = computed(() => {
      if (isLogin.value) {
        return 'Sign in';
      }
      if (isForgotten.value || isThankYouAfterForgotten.value) {
        return 'Reset Password';
      }
      return 'Register';
    });

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    watch(isLoginModalOpen, () => {
      if (isLoginModalOpen) {
        form.value = {};
        resetErrorValues();
      }
    });

    const setIsLoginValue = (value) => {
      resetErrorValues();
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      resetErrorValues();
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      setIsLoginValue(true);
      toggleLoginModal();
    };

    const handleForm = (fn) => async () => {
      resetErrorValues();

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.execute();
        form.value.recaptchaInstance = $recaptcha;

        await fn({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
            recaptchaToken,
          },
        });
      } else {
        await fn({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
          },
        });
      }

      const hasUserErrors = userError.value.register || userError.value.login;
      if (hasUserErrors) {
        error.login = userError.value.login?.message;
        error.register = userError.value.register?.message;
        return;
      }

      if (
        isSubscribed.value &&
        !isLogin.value &&
        !isForgotten.value &&
        !isThankYouAfterForgotten.value
      ) {
        await createContact({
          FIRSTNAME: form.value?.firstName,
          LASTNAME: form.value?.lastName,
          PRIVACY: privacyChecked.value,
          email: form.value?.email,
        });
        if (brevoError.value) {
          sendNotification({
            id: Symbol('user_form_error'),
            message: translate.t(
              'Newsletter subscription failed: data is not valid or email is already subscribed!'
            ),
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Error',
          });
        }
      }

      isLoggedIn.value = true;
      setIsLoginValue(true);
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await toggleLoginModal();
      await removeItem('checkout');

      router.push(app.localePath('/my-account'));
    };

    const hasLoggedIn = computed(() => {
      if (isLoggedIn && isLoggedIn.value) {
        return isLoggedIn.value;
      }
      return false;
    });

    watch(isLoggedIn, () => {
      if (isLoggedIn.value) {
        hasLoggedIn.value = true;
        return hasLoggedIn.value;
      }
      return false;
    });

    const handleRegister = async () => handleForm(register)();

    const handleLogin = async () => {
      await handleForm(login)();
      await loadWishlist('GlobalWishlist');
    };

    const handleForgotten = async () => {
      userEmail.value = form.value.username;

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();

        await request({ email: userEmail.value, recaptchaToken });
      } else {
        await request({ email: userEmail.value });
      }

      if (!forgotPasswordError.value.request) {
        isThankYouAfterForgotten.value = true;
        isForgotten.value = false;
      }
    };

    const changePrivacy = (value) => {
      privacyChecked.value = value;
    };

    return {
      barTitle,
      closeModal,
      createAccount,
      error,
      forgotPasswordError,
      forgotPasswordLoading,
      form,
      handleForgotten,
      handleLogin,
      handleRegister,
      isForgotten,
      isLogin,
      isLoginModalOpen,
      isSubscribed,
      isThankYouAfterForgotten,
      loading,
      rememberMe,
      setIsForgottenValue,
      setIsLoginValue,
      userEmail,
      userError,
      hasLoggedIn,
      isLoggedIn,
      isRecaptchaEnabled,
      user,
      privacyChecked,
      changePrivacy,
      brevoLoading,
    };
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    if (this.isRecaptchaEnabled.value) {
      // reset recaptcha
      this.$recaptcha.destroy();
    }
  },
});
</script>

<style lang="scss" scoped>
.login-modal {
  --modal-index: 50;
  --overlay-z-index: 49;
}

.form {
  &__element {
    margin: 0 0 var(--spacer-sm) 0;
  }

  .custom-button {
    width: 100%;
    margin-bottom: var(--spacer-xl);
  }
}

.forgot-password-message {
  font-size: var(--font-size--4xs);
  font-family: var(--font-family--secondary);
  color: var(--c-grey-2);
  margin: 0 0 var(--spacer-lg);
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 var(--spacer-xl) 0;
}

.bottom {
  text-align: center;
  font-size: var(--font-size--3xs);
  font-family: var(--font-family--secondary);
  color: var(--c-grey-2);
  line-height: var(--font-size--s);
}

.thank-you {
  &__paragraph {
    font-size: var(--font-size--4xs);
    color: var(--c-grey-2);
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
.password-error {
  //margin-bottom: 55px;
}
</style>

<style lang="scss">
.login-modal {
  .sf-overlay {
    background-color: var(--c-grey-3);
  }

  *::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: var(--c-grey-1);
  }

  *::-webkit-scrollbar {
    border-radius: 15px;
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: var(--c-grey-9);
    width: 10px;
  }

  .custom-button .disabled {
    opacity: 30%;
  }

  .sf-modal__container {
    z-index: 99;
    border-radius: 10px;
    width: 450px;
    height: 500px;

    .sf-icon {
      width: var(--spacer-sm);
      height: var(--spacer-sm);
    }

    .sf-button--pure {
      --button-color: var(--c-grey-8);
    }

    .sf-button--text {
      font-size: var(--font-size--4xs);
    }

    .sf-modal__content {
      padding: 106px 35px 35px;
    }

    .sf-custom-input {
      .form__element {
        height: 53px;
      }
    }

    .form__element[data-testid='password'] {
      margin-bottom: 10px;
    }

    .sf-input__error-message {
      font-size: var(--font-size--5xs);
      margin: 10px 10px 10px 0;
    }

    .sf-checkbox__message {
      font-size: var(--font-size--5xs);
    }

    input,
    select {
      box-shadow: none;
      outline: none;

      &:active,
      &:focus,
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }

    .form {
      label.sf-input__label {
        color: var(--c-grey-3);
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--xs);
        font-family: var(--font-family--secondary);
      }

      .sf-checkbox__label {
        font-size: var(--font-size--4xs);
        font-family: var(--font-family--secondary);
        color: var(--c-grey-2);
      }
    }

    .action *,
    .action {
      font-size: var(--font-size--4xs);
      font-family: var(--font-family--secondary);
      color: var(--c-grey-2);
      gap: 5px;
    }

    .sf-bar {
      background-color: #222a24;

      .sf-icon {
        fill: var(--c-white);
      }
    }

    .sf-bar__title {
      color: var(--c-white);
      font-size: var(--font-size--3xs);
      font-weight: 400;
    }
  }
}

@media (max-width: $tablet-max) {
  .login-modal {
    .sf-modal__container {
      width: 90vw;
      margin: 0 auto;

      .sf-modal__content {
        padding: 10vw 5vw 5vw;
      }

      .sf-bar {
        background-color: var(--c-grey-6);
      }

      .sf-input__error-message {
        margin: 0;
      }
    }
  }
}
</style>
