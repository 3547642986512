<template>
  <div v-if="$fetchState.pending || !blockData" />
  <VueSlickCarousel
    v-else
    class="logo-carousel"
    :arrows="false"
    :draggable="false"
    :autoplay="true"
    :infinite="true"
    :autoplay-speed="3000"
    :speed="3000"
    :slides-to-show="6"
    :slides-to-scroll="1"
    css-ease="linear"
    :pause-on-hover="false"
    :responsive="[
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ]"
  >
    <div
      v-for="(value, key, index) in blockData"
      :key="key"
      class="carousel__item"
    >
      <SfImage
        :src="value.logo"
        :alt="value.key || 'carousel-logo'"
        loading="lazy"
        image-tag="nuxt-img"
        :placeholder="
          addBasePath(
            logoList[index < 4 ? index : Math.floor(Math.random() * 4)].path
          )
        "
      />
    </div>
  </VueSlickCarousel>
</template>

<script>
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import {
  computed,
  defineComponent,
  ref,
  useFetch,
} from '@nuxtjs/composition-api';
import VueSlickCarousel from 'vue-slick-carousel';
import { useI18n } from '@/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    SfImage,
    VueSlickCarousel,
  },
  setup() {
    const logoList = [
      {
        key: 'samsung',
        path: 'brand/samsung.svg',
      },
      {
        key: 'sony',
        path: 'brand/sony.svg',
      },
      {
        key: 'apple',
        path: 'brand/apple.svg',
      },
      {
        key: 'lenovo',
        path: 'brand/lenovo.svg',
      },
    ];

    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`cmsBlockPrefooter-${locale}`);
    const prefooterSelector = `prefooter-${locale}`;
    const blockData = ref();

    useFetch(async () => {
      await loadBlocks({
        identifiers: [prefooterSelector],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        blockData.value = blocksContent[prefooterSelector].data.logos;
      } catch (e) {
        console.warn('Could not load prefooter block data.', e);
      }
    });

    return {
      logoList,
      addBasePath,
      prefooterSelector,
      blockData,
    };
  },
  computed: {
    copyrightContent() {
      return computed(() => contentGetters.getCmsBlockContent(this.blocks))
        .value[this.prefooterSelector].content;
    },
  },
});
</script>

<style lang="scss">
.logo-carousel {
  .slick-track {
    display: flex;
    flex-flow: row nowrap;
    background-color: var(--c-grey-8);
    align-items: center;
    height: 10vw;

    .slick-slide {
      text-align: center;
      height: 1.5vw;
      border-left: solid 1px var(--c-grey-2);
    }
  }

  .carousel__item {
    .sf-image {
      max-height: 1.5vw;
    }
  }
}
//mobile
@media (max-width: $mobile-max) {
  .logo-carousel {
    .slick-track {
      height: 17vw;

      .slick-slide {
        height: 4vw;
      }
    }

    .carousel__item {
      .sf-image {
        max-height: 4vw;
      }
    }
  }
}
</style>
